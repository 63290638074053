import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import StickyBox from 'react-sticky-box';

import Layout from '../components/layout';
import Share from '../components/Share';

import Authors from '../components/authors';
import richard from '../img/team/richard-dennis.jpg';
import gareth from '../img/team/gareth-owenson.jpg';

export default ({ data }) => {
  const node = data.markdownRemark;

  return (
    <Layout>
      <div className="skipScroll">
        <Helmet
          title={node.frontmatter.title}
          meta={[{ name: 'description', content: node.frontmatter.title }]}
        />
        <div className="hd-space" />

        <section className="bg-grey-2 skipScroll breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <a href="/">Home</a> >> <a href={`/${node.frontmatter.posttype}`}>{node.frontmatter.posttype.replace(/./,(s)=> s.toUpperCase())}</a> >> {node.frontmatter.category} >> {node.frontmatter.title}
              </div>
            </div>
          </div>
        </section>

        <section className="bg-grey-2 skipScroll pad20" style={{ overflow: 'visible' }}>
          <div className="container mt-3">
            <div className="row">
              <div className="col-md-8">
                <div className="blog-content mb-3">
                  <div className="blog-body">
                    <h1 className="display-2 font-weight-bolder mb-4 text-dark-blue">
                      {node.frontmatter.title}
                    </h1>

                    <div className="author-box-small pt-3 bg-grey pb-3 mb-3 border-bottomX">
                      <div className="author">
                        <img
                          src={require( `../img/${node.frontmatter.author_image}` )}
                          alt={node.frontmatter.author}
                          width={44} height={44}
                        />
                        <div className="font-weight-bold name">
                          {node.frontmatter.author}
                        </div>
                      </div>

                      <span className=" text-muted small">
                      <i className="fa fa-clock mr-2 ml-2" /> {node.frontmatter.date}
                    </span>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item small">
                          <span className="card-text text-muted"> Posted in </span> <span className="upper text-dark-blue font-weight-bolder">
                          {node.frontmatter.category}
                        </span>
                        </li>
                      </ul>
                    </div>

                    <Img
                      sizes={node.frontmatter.cover_image.childImageSharp.sizes}
                      className="post-img"
                    />

                    <Share
                      socialConfig={{
                        twitterHandle: `${data.site.siteMetadata.twitterHandle}`,
                        config: {
                          url: `${data.site.siteMetadata.url}${node.fields.slug}`,
                          title: `${node.frontmatter.title}`,
                        },
                      }}
                    />

                    <div className="card-bodyX mt-4">
                      <div dangerouslySetInnerHTML={{ __html: node.html }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <StickyBox
                  offsetTop={20}
                  offsetBottom={20}
                  style={{ border: '0px solid green' }}
                >

                  <div className="box border text-center box-paper">
                    <h4 className="display-5 font-weight-bold mb-3">Temporal White Paper</h4>
                    <Link to="/whitepaper" className="btn btn-outline-primary">Download</Link>
                  </div>

                  <h3 className="display-4X lead font-weight-bolder mt-5 mb-4X pb-3 headlineX redX">
                    Research Papers
                  </h3>

                  <ul className="list-group">
                    <li className="list-group-item">
                      <a href="/research/breaking-open-bazaar-identifying-exploiting-key-weaknesses-in-openbazaar-network" className="text-dark-blue">
                        Breaking open the Bazaar: identifying and exploiting key
                        weaknesses in the OpenBazaar network
                      </a></li>
                    <li className="list-group-item">
                      <a href="/research/temporal-blockchain-formal-analysis/" className="text-dark-blue">
                        A Temporal Blockchain: A Formal Analysis
                      </a></li>
                    <li className="list-group-item">
                      <a href="/research/rep-on-the-roll-peer-to-peer-reputation-system-rolling-blockchain/" className="text-dark-blue">
                        Rep on the Roll: A Peer to Peer Reputation System Based on a
                        Rolling Blockchain
                      </a></li>
                  </ul>

                  <h3 className="display-4X lead font-weight-bolder mb-5 pb-3 headline red mt-5">
                    Contributors
                  </h3>

                  <Authors img={gareth} title="Dr Gareth Owenson" description="CISO"/>
                  <Authors img={richard} title="Richard Dennis, MSc" description="Founder & CEO"/>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const blogQuery = graphql`
  query BlogPostQuery($slug: String!) {
    site {
      siteMetadata {
        url
        twitterHandle,
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        category
        author
        author_sub
        author_image
        posttype
        date(formatString: "DD MMMM, YYYY")
        cover_image {
          publicURL
          childImageSharp {
            sizes(maxWidth: 1240 ) {
              src
              sizes
              srcSet
              aspectRatio
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
